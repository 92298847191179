import IMask from 'imask';

// Source : https://imask.js.org/guide.html

class InputFormating {

	static init() {

    //American Phone Number
    let phoneMask = [];
    document.querySelectorAll('.js-input-phone').forEach((inputPhone, index) => {
      phoneMask[index] = IMask(inputPhone, {
        mask: '(000) 000-0000'
      })
    });


    //Postal Code
    let postalMask = [];
    document.querySelectorAll('.js-input-postal').forEach((inputPostal, index) => {
      postalMask[index] = IMask(inputPostal, {
          mask: 'a0a 0a0',
          prepare: function (str) {
            return str.toUpperCase();
          }
      })
    });


    //Zip Code
    let zipMask = [];
    document.querySelectorAll('.js-input-zip').forEach((inputZip, index) => {
      zipMask[index] = IMask(inputZip, {
        mask: '00000'
      })
    });
	}

}
export default InputFormating;