export default class SmootScrollbar {

    static init() {
        let Scrollbar = window.Scrollbar || null;
        let scrollbars = document.querySelectorAll('.smooth-scrollbar');
        
        if (Scrollbar === null) return;
        if (scrollbars.length === 0) return;

        scrollbars.forEach((scrollbar) => {

            // Options
            let options = {
                alwaysShowTracks: true,
                delegateTo: document.body,
                continuousScrolling: false,
                plugins: {
                    disableScroll: {
                        direction: 'x',
                    },
                },
            };

            // Register the plugin
            Scrollbar.use(DisableScrollPlugin);

            // Initialize the scrollbar
            let instance = Scrollbar.init(scrollbar, options);

            // Remove the horizontal scrollbar
            instance.track.xAxis.element.remove();
        });
    }
}

class DisableScrollPlugin extends window.Scrollbar.ScrollbarPlugin {
    
    transformDelta(delta) {
        if (this.options.direction) {
            delta[this.options.direction] = 0;
        }

        return Object.assign({}, delta);
    }
}

DisableScrollPlugin.pluginName = 'disableScroll';
DisableScrollPlugin.defaultOptions = {
    direction: '',
};