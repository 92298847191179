class HelpPopup {

    constructor () {
    	//Variables
        this.ctaContacts = document.querySelectorAll(".js-message__open-popup");
        this.ctaPopup = document.querySelector(".js-cta-help");
        this.ctaClosePopup = document.querySelector(".js-close-help");
        this.popupHelp = document.querySelector(".js-popup-help");
        this.chatCTA = document.querySelector(".js-chat");

        //Bindn
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.openChat = this.openChat.bind(this);

    	//Listeners
        if(this.ctaPopup) {
            this.ctaContacts.forEach((ctaContact) => ctaContact.addEventListener('click', e => this.openPopup(e)));
            this.ctaPopup.addEventListener("click", this.openPopup);
            this.ctaClosePopup.addEventListener("click", this.closePopup);
            this.chatCTA.addEventListener("click", this.openChat);
        }
    }

    openPopup(e){
        e.preventDefault();
        if (document.querySelector(".js-player-video iframe")) document.querySelector(".js-player-video iframe").contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        TweenMax.to(this.popupHelp, 0.3, {css:{autoAlpha:1}, ease:'Power1.easeOut'});
    }

    closePopup(){
        TweenMax.to(this.popupHelp, 0.3, {css:{autoAlpha:0}, ease:'Power1.easeOut'});
        if (document.querySelector(".js-player-video iframe")) {
            setTimeout(() => {
                document.querySelector(".js-player-video iframe").contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
            }, 300);
        }
    }
    openChat(e){
        e.preventDefault();
        LiveChatWidget.call("maximize");
    }

}

new HelpPopup();