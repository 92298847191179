window.REPTILE = window.REPTILE || {};

import '../sass/app.scss';
import BrowserInfo from './tools/BrowserInfo';
import InputFormating from './tools/InputFormating';
import Animator from './modules/Animator';
import Alert from "./partials/Alert";
import SmootScrollbar from "./partials/SmootScrollbar";
import HelpPopup from "./partials/HelpPopup";

class Main {

    constructor () {
    	BrowserInfo.init();
    	InputFormating.init();
        SmootScrollbar.init();
        document.addEventListener('DOMContentLoaded', this.ready.bind(this));
    }

    ready() {
        window.REPTILE.Animator = new Animator();
        this.alert = new Alert();
    }
}

new Main();