import jQuery from 'jquery';
import 'owl.carousel';

class CreateAccount {

    constructor() {
        this.helpTextField = document.querySelectorAll('.js-helptext-field input');


        // Listener
        if(this.helpTextField.length>0){
            this.helpTextField.forEach((helpTextField, index) => helpTextField.addEventListener('focus', e => this.showHelpText(index, e)));
            this.helpTextField.forEach((helpTextField, index) => helpTextField.addEventListener('focusout', e => this.hideHelpText(index, e)));
        }

        this.init();
    }

    showHelpText(i, e) {
        let helpText = e.currentTarget.parentElement.querySelector(".js-helptext");
        if (helpText) helpText.style.display = "block";
    }

    hideHelpText(i, e) {
        let helpText = e.currentTarget.parentElement.querySelector(".js-helptext");
        if (helpText) helpText.style.display = "none";
    }

    init() {
        // Init carousel
        let $targetEl = jQuery('.owl-carousel');
        if ($targetEl.length === 0) return;
        jQuery($targetEl).owlCarousel({
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplayTimeout: 7000,
            nav: false,
            dots: true,
            items: 1,
            autoHeight: false,
            responsive: {
                //
            }
        })
    }
}

new CreateAccount();