class Alert {

    constructor() {
        //Variables
        this.alert = document.querySelectorAll('.js-alert');
        this.header = document.querySelector('.js-header');
        this.messageCloseCta = document.querySelector('.js-message-close');
        this.bannerCloseCta = document.querySelector('.js-banner-close');

        //Bind
        this.pushHeader = this.pushHeader.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeBanner = this.closeBanner.bind(this);

        //Init
        if (this.alert) this.pushHeader();
        if (this.messageCloseCta) this.messageCloseCta.addEventListener('click', this.closeMessage);
        if (this.bannerCloseCta) this.bannerCloseCta.addEventListener('click', this.closeBanner);
    }

    pushHeader() {
        const alerts = [...this.alert];
        const calcHeight = alerts.map((alert) => alert.clientHeight).reduce((prev, current) => prev + current, 0);
        if (this.header) this.header.style.marginTop = calcHeight + 'px';
        
        if (alerts.length > 1) {
            const parentContainer = this.messageCloseCta.closest('.header__message-container');
            if (parentContainer) {
                parentContainer.style.top = alerts[0].clientHeight + 'px';
            }
        }
    }

    closeMessage(e) {
        const parent = e.currentTarget.closest('.header__col');

        if (parent) {
            parent.style.display = 'none';
            this.pushHeader();

            // Also set a cookie so it doesn't show up anymore
            const id = parent.dataset.id;
            let d = new Date();
            d.setTime(d.getTime() + (5000 * 24 * 60 * 60 * 1000));
            const expires = "expires=" + d.toUTCString();
            document.cookie = "banner-" + id + "=True;" + expires + ";path=/";
        }
    }

    closeBanner(e) {
        const parent = e.currentTarget.closest('.header__col');

        console.log("parent ", parent)

        if (parent) {
            parent.style.display = 'none';
            this.pushHeader();
        }
    }
}

export default Alert;
